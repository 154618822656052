<template>
  <div id="frame--box">
    <div v-if="loading">
      <div class="d-flex align-items-center justify-content-center p5">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <span class="text--black text--600 text--medium d-inline-flex ml-4"
          >Please wait</span
        >
      </div>
    </div>
    <!-- <iframe
      src=""
      width="100%"
      height="700px"
      allowfullscreen
      frameborder="0"
      scrolling="auto"
      sandbox
    >
      <p>
        <a href="/en-US/docs/Glossary">
          Fallback link for browsers that don't support iframes
        </a>
      </p>
    </iframe> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { GET_EXTENSION_APP } from "@/core/services/store/extensionapp.module"
  export default {
    name: "ExtensionApp",
    mounted() {
      this.getMyExtensionApp()
    },
    watch: {
      myExtensionApps: function(newVal) {
        if(newVal) {
          this.createIframe()
        }
      }
    },
    data() {
      return {}
    },
    methods: {
      getMyExtensionApp() {
        this.$store.dispatch(GET_EXTENSION_APP, { generalAppKey: this.$route.params.app_key })
          .then(() => {
            // this.createIframe()
          });
      },
      createIframe() {
        var i = document.createElement("iframe");
        HTMLIFrameElement.scrolling = "auto";
        i.style = "border: none";
        i.width = "100%";
        i.height = "700px";
        i.src = this.myExtensionApps.generalapp.url;
        document.getElementById("frame--box").appendChild(i);
        // this.loadIframe();
      },
      loadIframe() {
        if (window.addEventListener)
          window.addEventListener("load", this.createIframe(), false);
        else if (window.attachEvent)
          window.attachEvent("onload", this.createIframe());
        else window.onload = this.createIframe();
      },
    },
    computed: {
    ...mapState({
      myExtensionApps: (state) => {
        // console.log(state.extensionapp.myExtension)
        return state.extensionapp.myExtension ? state.extensionapp.myExtension : "";
      },
      loading: (state) => {
        return state.extensionapp.loading;
      },
    }),
  },
  }
</script>
<style scoped></style>
